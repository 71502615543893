import firebase from 'firebase/app';
import { Asset } from '../assets/Asset';
import { Investor } from '../users/User';

/**
 * Defining all the statuses a Payment can have.
 */
export const enum PaymentStatus {
  Open = 'open',
  Canceled = 'canceled',
  Pending = 'pending',
  Authorized = 'authorized',
  Expired = 'expired',
  Failed = 'failed',
  Paid = 'paid',
}

/**
 * Different payment gateways/providers we use.
 */
export enum PaymentProvider {
  Mollie = 'Mollie',
  /**
   * Manual, from Bloqadmin.
   */
  Custom = 'Custom',
}

/**
 * The upper db object for an Investment.
 * An investments always contains a subcollection of Payments.
 */
export interface Investment {
  id?: string;
  investor: firebase.firestore.DocumentReference | Investor;
  asset: firebase.firestore.DocumentReference | Asset;
  paidEuroTotal?: number;
  boughtSharesTotal?: number;
  createdDateTime: firebase.firestore.Timestamp;
  updatedDateTime?: firebase.firestore.Timestamp;
}

/**
 * Actual payment info is placed here.
 */
export interface Payment {
  id?: string;
  investor: firebase.firestore.DocumentReference | Investor;
  investment: firebase.firestore.DocumentReference | Investment;
  asset: firebase.firestore.DocumentReference | Asset;
  dividendsFormat: [string, number];
  provider: PaymentProvider;
  providerData: {
    id: string,
    amount: {
      currency: string,
      value: string | number,
    },
    status: PaymentStatus,
    metadata: {
      uid?: string,
      euroAmount: number,
      sharesAmount: number,
      investmentId?: string,
      assetId?: string,
      paymentId?: string,
      selectedDividendsFormatYear?: [string, number];
    },
    [key: string]: any,
  };
  type?: string;
  deleted: boolean;
  ended?: firebase.firestore.Timestamp;
  createdDateTime: firebase.firestore.Timestamp;
  updatedDateTime?: firebase.firestore.Timestamp;
  paymentDateTime?: firebase.firestore.Timestamp;
  endDateTime?: firebase.firestore.Timestamp;
}
