import Vue from 'vue';
import strings from './string';
import date from './date';

const filters = [strings, date];

// Adding the filters globally
filters.forEach((filterGroup): void => {
  Object.entries(filterGroup).forEach(([key, filter]): void => {
    Vue.filter(key, filter);
  });
});
