import Vue from 'vue';
import SingleLayout from '@/layouts/SingleLayout.vue';
import SingleFluidLayout from '@/layouts/SingleFluidLayout.vue';
import SidebarLayout from '@/layouts/SidebarLayout.vue';
import SidebarWideLayout from '@/layouts/SidebarWideLayout.vue';

Vue.component('SingleLayout', SingleLayout);
Vue.component('SingleFluidLayout', SingleFluidLayout);
Vue.component('SidebarLayout', SidebarLayout);
Vue.component('SidebarWideLayout', SidebarWideLayout);
