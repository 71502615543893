import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import { createModule } from 'vuex-toast';
import { State, generateInitialRootState } from '@/models/State';
import modules from './modules';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

Vue.use(Vuex);

export default <Store<State>> new Vuex.Store({
  state: generateInitialRootState() as State,
  modules: {
    toast: createModule({
      dismissInterval: 10000, // default time
    }),
    ...modules,
  },
  getters: {
    ...getters,
  },
  actions: {
    ...actions,
  },
  mutations: {
    ...mutations,
  },
  strict: process.env.NODE_ENV !== 'production',
});
