/**
 * Ceiling (round up) and forcing 2 decimals (Mollie) using a custom function since the native ceil is having issues with certain numbers
 * @param num number to ceil
 * @param scale number of decimals to ceil to
 */
export const ceilNumber = (num: number, scale: number): string => {
  if (!`${num}`.includes('e')) {
    // @ts-ignore
    return (+`${Math.ceil(`${num}e+${scale}`)}e-${scale}`).toFixed(scale);
  }
  const arr = `${num}`.split('e');
  let sig = '';
  if (+arr[1] + scale > 0) {
    sig = '+';
  }
  // @ts-ignore
  return (+`${Math.ceil(`${+arr[0]}e${sig}${+arr[1] + scale}`)}e-${scale}`).toFixed(scale);
};
