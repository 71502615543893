import moment from 'moment';
import { firebase } from '@/boot/firebase';
import { CustomTimestamp } from '@/models/CustomTimestamp';

/**
 * Formatting dates using moment
 *
 * Accepts an optional format parameter
 *
 * @example {{ myDate | transformDate }}
 * @example {{ myDate | transformDate('YYYY-MM-DD') }}
 */
export function transformDate(timestamp: firebase.firestore.Timestamp | Date, format?: string): string
export function transformDate(timestamp: undefined, format?: string): void
export function transformDate(timestamp: firebase.firestore.Timestamp | Date | undefined, format: string = 'DD-MM-YYYY'): string | void {
  if (!timestamp) {
    return;
  }
  // eslint-disable-next-line consistent-return
  return moment(timestamp instanceof firebase.firestore.Timestamp ? timestamp.toMillis() : timestamp).format(format);
}

/**
 * Transforms a CustomTimestamp object into a Date object
 * @param timestamp
 */
export const customTimestampToDate = (timestamp?: CustomTimestamp): Date | undefined => {
  if (!timestamp) {
    return undefined;
  }

  return (new firebase.firestore.Timestamp(timestamp._seconds, timestamp._nanoseconds)).toDate();
};

/**
 * Transforms a CustomTimestamp object into a Timestamp object
 * @param timestamp
 */
export const customTimestampToTimestamp = (timestamp?: CustomTimestamp): firebase.firestore.Timestamp | undefined => {
  if (!timestamp) {
    return undefined;
  }

  return (new firebase.firestore.Timestamp(timestamp._seconds, timestamp._nanoseconds));
};

/**
 * Converts a Timestamp object into a Date object
 * @param date
 */
export const timestampToDate = (date?: firebase.firestore.Timestamp): Date | undefined => {
  if (!date) {
    return undefined;
  }

  return date.toDate();
};

/**
 * Converts a Date or Timestamp object based on brower's timezone to Date object UTC based
 * @param date
 */
export const convertLocalDateToUTC = (date?: Date | firebase.firestore.Timestamp, excludeTime?: boolean): Date | undefined => {
  if (!date) {
    return undefined;
  }

  const tempDate = date instanceof Date ? date : timestampToDate(date as firebase.firestore.Timestamp)!;

  const timeParams = [
    tempDate.getHours(),
    tempDate.getMinutes(),
    tempDate.getSeconds(),
    tempDate.getMilliseconds(),
  ];

  const newDate = Date.UTC(
    tempDate.getFullYear(),
    tempDate.getMonth(),
    tempDate.getDate(),
    ...!excludeTime ? timeParams : [],
  );

  return new Date(newDate);
};

/**
 * Converts milliseconds or Timestamp object based on UTC date to a Date object based on current timezone
 * @param utcDate
 */
export const convertUTCToLocalDate = (utcDate: Date | number | firebase.firestore.Timestamp): Date | undefined => {
  if (!utcDate) {
    return undefined;
  }

  return utcDate instanceof firebase.firestore.Timestamp ? timestampToDate(utcDate) : new Date(utcDate);
};

export default {
  transformDate,
  customTimestampToDate,
  customTimestampToTimestamp,
  timestampToDate,
  convertLocalDateToUTC,
  convertUTCToLocalDate,
};
