import { Manager, ManagerInterface, ManagerRole, ManagerStatus } from './Manager';

export interface CurrentManagerInterface extends ManagerInterface {
}

/**
 * CurrentManager stands for a logged-in application manager.
 */
export class CurrentManager extends Manager implements CurrentManagerInterface {
  /**
   * Constructor.
   */
  public constructor(
    role: ManagerRole,
    uid: string,
    email: string,
    createdAt?: string,
    lastSignInAt?: string,
  ) {
    super(uid, ManagerStatus.Enabled, role, email, createdAt, lastSignInAt);
  }
}
