import Vue, { VNode } from 'vue';
import { registerLocale } from 'i18n-iso-countries';
import router from '@/boot/router';
import store from '@/store';
import { firebase } from '@/boot/firebase';
import App from '@/App.vue';
import '../filters';
import { PaymentStatus } from '@/models/investments/Investment';

/**
 * Register countries list in English locale
 *
 * @see https://github.com/michaelwittig/node-i18n-iso-countries
 */
registerLocale(require('i18n-iso-countries/langs/en.json'));

/**
 * Vue.
 *
 * @see https://vuejs.org/v2/api/
 */
let app: Vue;

Vue.config.productionTip = false;

/**
 * We are exporting the collection promises from the bindings
 * so we can know when and where the data is still loading.
 * We also have to define the levels so Vue can attach observability when needed.
 */
export const collections: { [key: string]: Promise<any> | null } = {
  settings: null,
  identificationSettings: null,
  manager: null,
  restoration: null,
  specialUsers: null,
  dataChangeRequests: null,
  loanPayments: null,
  openPayments: null,
};

/**
 * Initialize Vue after Firebase is initialized. We use
 * Vuexfire to bind Firestore collections/documents.
 *
 * @see https://github.com/posva/vuexfire/tree/firestore
 */
export default function(
  authInstance: firebase.auth.Auth,
  firestoreInstance: firebase.firestore.Firestore,
): Vue {
  collections.settings = store.dispatch('bindFirestoreReference', {
    name: 'settings',
    ref: firestoreInstance.collection('settings').doc('admin'),
  });

  collections.identificationSettings = store.dispatch('bindFirestoreReference', {
    name: 'identificationSettings',
    ref: firestoreInstance.collection('settings').doc('identification'),
  });

  authInstance.onAuthStateChanged(async (user: firebase.User | null): Promise<void> => {
    if (!app) {
      /* eslint-disable no-new */
      app = new Vue({
        el: '#app',
        router,
        store,
        render: (h): VNode => h(App),
      });
    }

    const checkMultifactor = (currentUser: firebase.User): boolean => currentUser.multiFactor.enrolledFactors.some(
      (multiFactor): boolean => multiFactor.factorId === firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID,
    );
    if (user && checkMultifactor(user)) {
      // Bind user data
      // @see https://github.com/posva/vuexfire/tree/firestore
      collections.manager = store.dispatch('bindFirestoreReference', {
        name: 'manager',
        ref: firestoreInstance.collection('specialUsers').doc(user.uid),
      });

      const currentUser = await user.getIdTokenResult();

      if (currentUser.claims.superadmin || currentUser.claims.admin) {
        collections.restoration = store.dispatch('bindFirestoreReference', {
          name: 'restoration',
          ref: firestoreInstance.collection('restoration'),
        });

        collections.investments = store.dispatch('bindFirestoreReference', {
          name: 'investments',
          ref: firestoreInstance.collection('investments').orderBy('updatedDateTime', 'desc'),
        });

        collections.specialUsers = store.dispatch('bindFirestoreReference', {
          name: 'specialUsers',
          ref: firestoreInstance.collection('specialUsers'),
        });

        collections.loanPayments = store.dispatch('bindFirestoreReference', {
          name: 'loanPayments',
          ref: firestoreInstance.collectionGroup('payments')
            .where('deleted', '==', false)
            .where('type', '==', 'loan'),
        });

        collections.openPayments = store.dispatch('bindFirestoreReference', {
          name: 'openPayments',
          ref: firestoreInstance.collectionGroup('payments')
            .where('deleted', '==', false)
            .where('providerData.status', '==', PaymentStatus.Open),
        });

        collections.dataChangeRequests = store.dispatch('bindFirestoreReference', {
          name: 'dataChangeRequests',
          ref: firestoreInstance.collection('dataChangeRequests'),
        });
      }

      // Ensure user is always set in state, so also after page refresh of already logged
      // in session. To do so, we dispatch setAuthenticatedUser action, which commits a mutation.
      store.dispatch('setAuthenticatedUser', { user });
    } else {
      // Unbinds all data that is binded in the collections object at logout
      Object.keys(collections).forEach((name: string): void => {
        if (collections[name] !== null) {
          store.dispatch('unbindFirestoreReference', { name });
        }
      });
    }
  });

  return app;
}
