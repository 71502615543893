/* eslint-disable max-len */
// @ts-ignore
import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { IconDefinition, library } from '@fortawesome/fontawesome-svg-core';
// Solid icons
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons/faBriefcase';
import { faBinoculars } from '@fortawesome/free-solid-svg-icons/faBinoculars';
import { faBuilding } from '@fortawesome/free-solid-svg-icons/faBuilding';
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faCheckCircle as farCheckCircle } from '@fortawesome/free-regular-svg-icons/faCheckCircle';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faCircle } from '@fortawesome/free-regular-svg-icons/faCircle';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch';
import { faCloud } from '@fortawesome/free-solid-svg-icons/faCloud';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faComment } from '@fortawesome/free-solid-svg-icons/faComment';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faExpand } from '@fortawesome/free-solid-svg-icons/faExpand';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { faFutbol } from '@fortawesome/free-solid-svg-icons/faFutbol';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faMailBulk } from '@fortawesome/free-solid-svg-icons/faMailBulk';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faMoneyCheck } from '@fortawesome/free-solid-svg-icons/faMoneyCheck';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faShapes } from '@fortawesome/free-solid-svg-icons/faShapes';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons/faTachometerAlt';
import { faThLarge } from '@fortawesome/free-solid-svg-icons/faThLarge';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faUserEdit } from '@fortawesome/free-solid-svg-icons/faUserEdit';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faVectorSquare } from '@fortawesome/free-solid-svg-icons/faVectorSquare';
import { faFile } from '@fortawesome/free-solid-svg-icons/faFile';
// Brands icons
// @ts-ignore
import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub';

// Add icons to library
library.add(
  faBell as IconDefinition, faBriefcase as IconDefinition, faBinoculars as IconDefinition, faBuilding as IconDefinition, faCalendar as IconDefinition, faCheck as IconDefinition, faCheckCircle as IconDefinition, faChevronLeft as IconDefinition,
  faChevronRight as IconDefinition, faChevronUp as IconDefinition, faCircle as IconDefinition, faCircleNotch as IconDefinition, faCloud as IconDefinition, faCog as IconDefinition, faComment as IconDefinition, faExclamationCircle as IconDefinition,
  faQuestionCircle as IconDefinition, faExclamationTriangle as IconDefinition, faExpand as IconDefinition, faExternalLinkAlt as IconDefinition, faFutbol as IconDefinition, faGithub as IconDefinition, faInfoCircle as IconDefinition,
  faMailBulk as IconDefinition, faMapMarkerAlt as IconDefinition, faMinus as IconDefinition, faMoneyCheck as IconDefinition, faPlusCircle as IconDefinition, farCheckCircle as IconDefinition, faSearch as IconDefinition, faShapes as IconDefinition,
  faSignOutAlt as IconDefinition, faSpinner as IconDefinition, faStar as IconDefinition, faTachometerAlt as IconDefinition, faThLarge as IconDefinition, faTimes as IconDefinition, faTimesCircle as IconDefinition,
  faTrash as IconDefinition, faUser as IconDefinition, faUserEdit as IconDefinition, faUsers as IconDefinition, faVectorSquare as IconDefinition, faFile as IconDefinition,
);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);

// @ts-ignore
export { FontAwesomeIcon, library };
