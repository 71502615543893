













import { Component, Vue } from 'vue-property-decorator';
import { State as StateClass } from 'vuex-class';
import { State } from '@/models/State';
import SidebarNavigation from '@/components/navigation/Sidebar.vue';

@Component({
  components: {
    SidebarNavigation,
  },
})

export default class SidebarLayout extends Vue {
  @StateClass('outlineMode') outlineMode!: State['outlineMode'];
}
