import { Module } from 'vuex';
import { State } from '@/models/State';
import asset from './asset';
import user from './user';
import project from './project';
import identificationRequest from './identificationRequest';
import payment from './payment';
import { Vertebra } from '../utils/skeleton';

export default <{ [key: string]: Module<Vertebra, State> }>{
  asset,
  user,
  identificationRequest,
  payment,
  project,
};
