import Vue from 'vue';
/**
 * Bootstrap 4 core.
 *
 * @see https://getbootstrap.com/docs/4.1/getting-started/webpack/
 */
import * as $ from 'jquery';
import 'bootstrap';
/**
 * Code syntax highlighting using Highlight.js.
 *
 * @see https://highlightjs.org
 */
// Highlight.js Vue directive for syntax highlighting
// @ts-ignore
import VueHighlightJS from 'vue-highlightjs';

// jQuery
// @ts-ignore
(global as any).$ = $;

Vue.use(VueHighlightJS);
