import firebase from 'firebase/app';

/**
 * Describing the iR status.
 */
export enum IdentificationRequestStatus {
  Initial = 'initial',
  Approved = 'approved',
  Rejected = 'rejected',
}

export enum UserGender {
  Male = 'm',
  Female = 'f',
}

/**
 * Basic iR interface.
 */
export type PrivateIdentification = {
  id?: string;
  bankAccount?: string;
  city: string;
  country: string;
  dateOfBirth: firebase.firestore.Timestamp;
  gender: UserGender;
  houseNumber: string;
  message?: string;
  name: string;
  nationality: string;
  passport: string;
  postalCode: string;
  status: IdentificationRequestStatus;
  streetAddress: string;
  surname: string;
  telephone?: string;
  deleted: boolean;
  accreditated: boolean;
  investorGoal?: string;
  countryTax: string;
  taxIdNum: string;
  createdDateTime: firebase.firestore.Timestamp;
  updatedDateTime: firebase.firestore.Timestamp;
}

/**
 * BusinessIdentification type.
 */
export interface BusinessIdentification extends PrivateIdentification {
  companyName: string;
  companyType: string;
  incorporationDate: firebase.firestore.Timestamp;
  incorporationCountry: string;
  businessAddress: string;
  taxId: string;
  taxDoc: string;
}

export type IdentificationRequest = PrivateIdentification | BusinessIdentification;

/**
 * Type guard to check the type of Identification Request.
 * @param iRequest basic Identification Request interfaces.
 */
export const isBusiness = (iRequest: IdentificationRequest): iRequest is BusinessIdentification => !!(iRequest as BusinessIdentification).taxId;
