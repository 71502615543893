/* eslint-disable prefer-destructuring */

/**
 * Custom charts.
 *
 * @see vendor/dashkit/src/assets/js/charts.js
 * @see https://github.com/chartjs/Chart.js
 * @see https://vue-chartjs.org/#/home?id=custom-new-charts
 */
import Chart from 'chart.js';

export const ChartColors: { [key: string]: { [key: number]: string } | string } = {
  gray: {
    300: '#E3EBF6',
    600: '#95AAC9',
    700: '#6E84A3',
    800: '#152E4D',
    900: '#283E59',
  },
  primary: {
    100: '#D2DDEC',
    300: '#A6C5F7',
    700: '#2C7BE5',
  },
  black: '#12263F',
  white: '#FFFFFF',
  transparent: 'transparent',
};

// Legend
// @ts-ignore
Chart.defaults.global.legend.display = false;
// @ts-ignore
Chart.defaults.global.legend.position = 'bottom';
// @ts-ignore
Chart.defaults.global.legend.labels.usePointStyle = true;
// @ts-ignore
Chart.defaults.global.legend.labels.padding = 16;

// Responsive
Chart.defaults.global.responsive = true;
Chart.defaults.global.maintainAspectRatio = false;

// Type
// @ts-ignore
Chart.defaults.global.defaultColor = ChartColors.gray[600];
Chart.defaults.global.defaultFontColor = ChartColors.gray[600];
Chart.defaults.global.defaultFontFamily = 'Cerebri Sans';
Chart.defaults.global.defaultFontSize = 13;

// Layout
// @ts-ignore
Chart.defaults.global.layout.padding = 0;

// Elements
// @ts-ignore
Chart.defaults.global.elements.point.radius = 0;
// @ts-ignore
Chart.defaults.global.elements.point.backgroundColor = ChartColors.primary[700];
// @ts-ignore
Chart.defaults.global.elements.line.tension = 0.4;
// @ts-ignore
Chart.defaults.global.elements.line.borderWidth = 3;
// @ts-ignore
Chart.defaults.global.elements.line.borderColor = ChartColors.primary[700];
// @ts-ignore
Chart.defaults.global.elements.line.backgroundColor = ChartColors.transparent;
// @ts-ignore
Chart.defaults.global.elements.line.borderCapStyle = 'rounded';
// @ts-ignore
Chart.defaults.global.elements.rectangle.backgroundColor = ChartColors.primary[700];
// @ts-ignore
Chart.defaults.global.elements.arc.backgroundColor = ChartColors.primary[700];
// @ts-ignore
Chart.defaults.global.elements.arc.borderColor = ChartColors.white;
// @ts-ignore
Chart.defaults.global.elements.arc.borderWidth = 4;

// Tooltips
Chart.defaults.global.tooltips.enabled = false;
// @ts-ignore
Chart.defaults.global.tooltips.mode = 'index';
// @ts-ignore
Chart.defaults.global.tooltips.intersect = false;
// @ts-ignore

// yAxes
Chart.scaleService.updateScaleDefaults('linear', {
  gridLines: {
    borderDash: [2],
    color: ChartColors.gray[300],
    drawBorder: false,
    drawTicks: false,
    lineWidth: 0,
    zeroLineWidth: 0,
    zeroLineColor: ChartColors.gray[300],
    zeroLineBorderDash: [2],
  },
  ticks: {
    beginAtZero: true,
    padding: 10,
    callback(value: any): string | null {
      if (!(value % 10)) {
        return value;
      }

      return null;
    },
  },
});

// xAxes
Chart.scaleService.updateScaleDefaults('category', {
  gridLines: {
    drawBorder: false,
    drawOnChartArea: false,
    drawTicks: false,
  },
  ticks: {
    padding: 20,
  },
});
