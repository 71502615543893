







































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter, State as StateClass } from 'vuex-class';
import { State } from '@/models/State';
import { ManagerRole } from '@/models/manager/Manager';
import { CurrentManager } from '@/models/manager/CurrentManager';

@Component({
})

export default class Sidebar extends Vue {
  @Getter getCurrentManager!: CurrentManager;
  @Getter getCurrentManagerRole!: ManagerRole;
  @Getter getManagerProfileData!: { [key: string]: string } | null;
  @Action logOut!: Function;
  @Action toggleOutlineMode!: Function;
  @StateClass('outlineMode') outlineMode!: State['outlineMode'];

  sandboxItems: object[] = [
    { title: 'Alerts' },
    { title: 'Avatars' },
    { title: 'Badges' },
    { title: 'Breadcrumb' },
    { title: 'Buttons' },
    { title: 'Button group' },
    { title: 'Cards' },
    { title: 'Charts' },
    { title: 'Dropdowns' },
    { title: 'Forms' },
    { title: 'Icons' },
    { title: 'Lists' },
    { title: 'Loaders' },
    { title: 'Modals' },
    { title: 'Navs' },
    { title: 'Navbar' },
    { title: 'Page headers' },
    { title: 'Pagination' },
    { title: 'Popovers' },
    { title: 'Progress' },
    { title: 'Social post' },
    { title: 'Tables' },
    { title: 'Tooltips' },
    { title: 'Typography' },
    { title: 'Utilities' },
  ];

  /**
   * Returns current outline mode from store.
   */
  get currentOutlineMode(): boolean {
    return this.outlineMode;
  }

  /**
   * Computed property that holds username.
   */
  get userName(): string {
    // Name set in Firestore
    if (this.getManagerProfileData) {
      return this.getManagerProfileData.name || '';
    }

    return '';
  }

  /**
   * Computed property that holds user e-mail address.
   */
  get userEmail(): string {
    if (this.getCurrentManager) {
      return this.getCurrentManager.email || '';
    }

    return '';
  }

  /**
   * Computed property whether we should show dev menu.
   */
  get showDevelopmentMenu(): boolean {
    return this.getCurrentManagerRole === ManagerRole.Superadmin && (process.env.NODE_ENV as string || 'unknown') !== 'production';
  }

  /**
   * Show managers menu, which depends on role.
   */
  get showManagersMenuLink(): boolean {
    return this.getCurrentManagerRole === ManagerRole.Superadmin || this.getCurrentManagerRole === ManagerRole.Admin;
  }

  /**
   * Current Firebase project ID.
   */
  get firebaseProjectId(): string {
    return process.env.VUE_APP_BLOQADMIN_FIREBASE_PROJECT_ID || 'unknown';
  }
}
